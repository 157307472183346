/*==========  Mobile First Method  ==========*/
html {
  font-size: 14px;
}

#slide {
  h2 {
	font-size: 1rem;
  }

  p {
	font-size: .8rem;
  }
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
	.nosaltresbackground {
		h3 {
			font-size: 50px;
			font-weight: normal;
			font-family: "Circular3";
			margin-bottom: 2.5rem;
		}
	}

	.equipbackground {
		h1 {
			font-size: 50px !important;
			font-weight: bolder !important;
			font-family: "Circular3" !important;
			margin-bottom: 2.5rem;
		}
	}
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 769px) {

  header {
	position: sticky;
	top: 0;
	z-index: 100;
	background: #fff;
	transition: 0.3s min-height;
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 5px 0 rgb(0 0 0 / 23%);

	&.compressed {
	  /*	  #logo h1 {
			  font-size: 2rem;
			}
	  */
	}

	> .container:first-of-type {
	  padding-top: .5rem;
	  padding-bottom: .5rem;
	}

	.bg-menu {
	  background: $menuBg;
	  /*border-bottom: 1px solid $menuColorSeparator;*/
	}

	#logo {
	  img {
		width: 70px;
		height: auto;
	  }

	  h1 {
		color: $primary;
		font-size: 2.5rem;
		transition: 0.3s all;
	  }
	}

  }

  #slide {
	h2 {
	  font-size: 3rem;
	}

	p {
	  font-size: 2rem;
	}
  }

  .icons {
	display: flex;

	a {
	  background: $boxBg;
	  width: 2.4rem;
	  height: 2.4rem;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  border-radius: 100%;
	  margin-left: 0.5rem;
	  color: $boxColor;

	  &:hover {
		color: #000;
	  }
	}

	i {
	  font-size: 1.2rem;
	}
  }

  .swiper-pagination-bullet-active {
	width: 100px !important;
	height: 10px !important;
	border-radius: 0% !important;
	}

.swiper-pagination-bullet {
	width: 100px !important;
	height: 10px !important;
	border-radius: 0% !important;
	}

	.habitatgesportada {
		margin-top: 2rem;
	}

	.som {
		margin-top: 2.5rem;
	}

	.mosaic {
		img{
			height: 210px;
			object-fit: cover;
		}
	}

	#form-contacte-1 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	
		& > div {
			width: 33%;
			margin-right: 10px;
			justify-content: space-evenly;
	
			&:first-of-type {
				width: 50%;
			}
	
			&:nth-of-type(3) {
				display: inline-flexbox;
				width: 23%;
			}
	
			&:nth-of-type(6) {
				width: 74%;
				text-align: center;
				margin-right: 0px;
	
				> div > div {
					width: 100%;
					display: flex;
					justify-content: space-between;
					text-align: center;
	
					label {
						display: flex;
						flex-direction: column;
						justify-content: center;
						text-align: center;
						align-items: center;
	
						img {
							display: block;
							margin: 0px !important;
						}
					}
				}
			}
	
			&:nth-of-type(7) {
				width: 24%;
				align-self: center;
			}
	
			&:nth-of-type(8) {
				width: 100%;
			}
	
			&:nth-of-type(9) {
				width: 100%;
				justify-content: center;
				text-align: center;
				align-items: center;
			}
	
			&:nth-of-type(10) {
				width: 100%;
				display: flex;
				justify-content: center;
				.g-recaptcha {
					display: flex;
					justify-content: center;
				}
			}
		}
	
		input {
			border-radius: 0;
			border: none;
			background-color: #C7B299;
		}
	
		input[type="submit"] {
			display: block;
			width: 100%;
		}
	
		.form-check-input {
			margin-right: 1rem !important;
		}
	
		.form-check .form-check-input {
			float: none !important;
		}
	
		h4{
			display: inline;
		}
		
		.jumbotron { 
	
			h1{
			text-align: left !important;
			   }
			 }
		}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  html {
	font-size: 16px;
  }

  footer {
	
	#footerweb {
		transform: rotate(-90deg);
	}

	#footeremail {
		transform: rotate(-270deg);
	}
	background-image: url("../img/Background5.png");
	background-color: white;
	background-repeat: no-repeat;
	background-position: 110% 0%;
  }

  .equipbackground {
	background-image: url("../img/background11.jpg");
	background-repeat: no-repeat;
	background-position: center 605px;
	background-size: 880px;
 	background-attachment: fixed

	}

	.nosaltresbackground {
		background-image: url("../img/Background10.jpg");
		background-repeat: no-repeat;
		background-position: center 35px;
		height: 1550px;
		font-size: 30px;	
		font-family: "Circular1";
		font-weight: lighter;
  		background-attachment: fixed;

		h3 {
			font-size: 75px;
			font-weight: bolder;
			font-family: "Circular3";
			margin-bottom: 3.5rem;
		}
	}

	#lequip{
		.jumbotron {
			h1 { 
			font-size: 75px !important;
			font-weight: normal;
			font-family: "Circular3";
			}
		}
		}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
	#productep {
		p {
			font-size: 1.2rem;
		}
	}
	.producte, h4{
		font-size: 1.7rem;
	}	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1400px) {
  html {
	font-size: 16px;
  }
}


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .nivo-caption .tbl .tbl-cell {
	vertical-align: middle;
  }

  #logo-small{
	img{
		margin-left: 1rem !important;
	}
  }

  #logo {
	display: none !important;
  }
  
  footer {
	background-image: url("../img/Background5.png");
	background-color: white;
	background-repeat: no-repeat;

  	  .column {
		width: 100% !important;
	  }
	}

	#contacte {
		.jumbotron h1 {
			margin-top: 2rem;
		}
		
	}

	#lequip {
		.jumbotron {
			h1 {
				font-size: 50px !important;
				font-weight: normal;
				margin-bottom: 1rem;
			}
		}
		}
	.rr,
	.he,
	.ee,
	.ot {
		p {
			margin-bottom: 1rem;
		}
		h3 {
			font-size: 50px !important;
		}
	}

	body {
		padding-top: 3rem;
	  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {

  body {
	padding-top: 4rem;
  }

  .container {
	min-width: 100%;
  }

  .jumbotron {
	padding: 0;
	margin: 0;
  }
  .jumbotron h1 {
	font-size: 2rem;
	padding: 1rem;
  }

  .idiomes li a {
	padding: 0.4rem;
  }

	.has-dropdown {
		flex-direction: column !important;
	}
	
	iframe {
		width: 500px !important;
	}

	#contacte {
		.jumbotron h1 {
			margin-top: 0;
		}
		
	}

	.rr,
	.he,
	.ee,
	.ot {
		.container {
		margin-top: 0rem;
	}

		h4 {
			font-size: 1.3rem !important;
		}
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
	iframe {
		width: 400px !important;
	}
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
	iframe {
		width: 300px !important;
	}
}

@media (min-width: 1200px) {
  .container {
	max-width: 1200px;
  }
}
