html {
	font-size: 18px !important;
	font-family: "Circular1";
	color: #8c6f4b;
}

html,
body {
	width: 100%;
	position: relative;
	margin: 0;
	padding: 0;
}

body {
	min-height: 100vh;
	font-family: "Circular2";
	color: #8c6f4b;
	
	.rr,
	.he,
	.ee,
	.ot {

		h2{
			font-family: "Circular3";
			font-weight: 500;
		}
		h3 {
			font-size: 75px;
			font-family: "Circular3";
			font-weight: 500;
		}

		h4 {
			font-size: 1rem;
			font-family: "Circular3";
			font-weight: normal;
		}

		h6{
			align-self: flex-end;
			font-size: 45px;
			font-family: "Circular3";
			font-weight: lighter;
		}

		p {
			font-family: "Circular1";
			font-size: 1.2rem;
		}
	}
}

.som {
	text-align: center;
	font-size: 40px;
	padding: 0px;
	line-height: 1, 5;

	display: flex;
	justify-content: space-between;
	align-content: center;

	p {
		font-weight: bolder;
		margin-bottom: 1rem;
	}

	h6 {
		font-weight: 100;
		font-size: 40px;
		font-family: "Circular1";
		margin-bottom: 0px;
		line-height: 0.5;
	}

	.lliures {
		width: 100%;
	}

	img{
		height: 100px;
		width: auto;
	}
}

#portada-slide {
	font-family: "Circular1";
	font-size: 2rem !important;
}

#textslide{
		font-weight: 550;
		font-family: "Circular1";
		font-size: 3.5rem !important;
		line-height: 1;
}

#textslide2 {
	font-weight: 400;
	font-family: "Circular1";
	font-size: 3.5rem !important;
	line-height: 1;
	margin-bottom: 5px;
}

#productep {
	p {
		font-family: "Circular1";
	}	
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-family: "Circular2";
	font-weight: bold;

	a {
		color: inherit;
	}
}

ul,
p {
	margin: 0;
	padding: 0;
}

.wow {
	visibility: hidden;
}

a {
	color: $primary;
	text-decoration: none;
}

header {
	a:hover {
		text-decoration: none;
	}
}

main {
	overflow: hidden;
	clear: both;
	min-height: 18rem;
	position: relative;
	padding-bottom: 2.5rem;

	ul {
		margin: 0 0 0 2.5rem;
		padding: 0;
	}
}

footer {
	display: flex;
	flex-direction: column;
	background: $primary;
	color: white;
	padding: 3rem 0;
	font-size: 18px;

	p {
		margin: 0;
		padding: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
		color:#8c6f4b;
	}

	a i {
		color: inherit;
	}

	.container {
		display: flex;
	}

	.column {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	#footerweb1, #footeremail1, #footercontacte1 {
		font-family: "Circular1";
	}
}

.idiomes {
	list-style: none;

	li {
		float: left;
		display: inline;
		margin: 0 3px;
	}

	li a {
		color: #333;
	}

	li a.selected {
		color: #333;
		font-weight: bold;
	}
}

.equipbackground {

	h3 p {
		font-family: "Circular1";
		font-size: 1rem;
		font-weight: normal;
		margin-bottom: 1rem;
	}

	p {
		font-family: "Circular1";
	}
}

.habitatgesportada {
	h3{
		font-family: "Circular3";
		font-weight: normal;
		font-size: 1.5rem;
	}
}

.card {
	background-color: #8c6f4b5e;
}

	

	.jumbotron {
	background: #fff;
	padding: 30px 0;
	border-radius: 0;
	border: none;
	margin: 0;

	h1 {
		color: $primary !important;
		text-align: center;
		font-size: 4.5rem;
		margin: 0;
		font-weight: 400;
		font-family: "Circular3";
		}
	}

	#fletxes {
		flex-direction: row;
		flex-wrap: wrap;
		h4 {
			display: inline;
			font-family: "Circular1";
		}
		svg {
			display: inline;
			max-height: 50px;
		}

		nav {
			flex-direction: row;
			flex-wrap: wrap;
		}

		div {
			display: inline !important;
		}
		
	}

	#fletxes1 {
		float: right;
		text-align: right;
	}

	.nosaltresbackground {
		font-family: Circular1;

		img {
			width: auto !important;
		}
	}

	.fa-angle-up {
		color:#8c6f4b !important;
	}

	.card {
		border-radius: 0px  !important;
	}

	.textfletxes {
		font-size: 20px;
		font-style: italic;
	}

	a:hover {
		color:#8c6f4b;
	}


	.bloc-logo{
		
		img{
			max-width: 100%;
		}

	}