
// custom variables
$menuBg: #fff;
$menuColor: #8d6e4b;
$menuColorHover: $primary;
$menuColorSelected: #000000;
$menuColorSeparator: darken($menuBg, 20%);
$menuBgSubmenu: #fff;

$boxBg: #f8f9fa;
$boxColorSeparator: #dadce0;
$boxColor: #202124;
