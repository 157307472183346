// Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;0,700;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

@font-face {
    font-family: 'Circular';
    src: url('fons/circularstd-bold.woff2') format('woff2'),
        url('fons/circularstd-bold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular2';
    src: url('fons/circularstd-book.woff2') format('woff2'),
        url('fons/circularstd-book.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular3';
    src: url('fons/circularstd-medium.woff2') format('woff2'),
        url('fons/circularstd-medium.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// Dependences
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css"); // Si ho cridem en local no va bé
/*$fa-font-path: "~@fortawesome/fontawesome-free/webfont";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";*/

@import "~animate.css";
@import "~swiper/css/bundle";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import "~superfish/dist/css/superfish.css";
@import "~owl.carousel";
@import "~datatables/media/css/jquery.dataTables.min.css";
@import "~datatables.net-bs4";

// Bootstrap
@import "./variables_bootstrap";
@import "~bootstrap";

// Estils locals
@import "_variables";
@import "components";
@import "main";
@import "menu_responsive";
@import "mediaqueries";

